<template>
  <div>
    <div>
      <div class="alert alert-dark">
        Enter the primary email address for this domain
      </div>
      <div class="d-flex py-2">
        <div class="col-auto my-auto px-0 me-2">
          <input type="text" v-model="form.email" class="form-control" />
        </div>
        <div class="col-auto my-auto">
          <p class="mb-0 fw-bold">@{{ domainCopy.domain }}</p>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-auto">
        <button
          class="btn btn-primary"
          :disabled="!form.email"
          @click="verifyWithSendgrid"
        >
          Create Primary Sender Address
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domain: {
      type: Object,
    },
  },
  data() {
    return {
      domainCopy: this.domain,
      form: {
        email: "",
      },
    };
  },
  methods: {
    verifyWithSendgrid() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/domains/" +
            this.$route.params.id +
            "/add-primary-sender",
          this.form
        )
        .then(({ data }) => {
          this.domainCopy = data.domain;
          this.$EventBus.$emit("alert", { message: data.message });
          this.$emit("updated", data.domain);
        });
    },
  },
};
</script>

<style>
</style>