<template>
  <div>
    <div class="card border-0 bg-light shadow-none mb-4">
      <div class="card-body">
        <h4 class="text-primary fw-bold">Sender Addresses</h4>
        <p class="text-dark mb-4">
          Add a sender address for this domain, for example
          <strong>info</strong> or <strong>hello</strong> - you only need to add
          your address prefix
        </p>
        <form @submit.prevent="addDomainAddress" class="mb-4">
          <div class="row">
            <div class="col-auto mt-auto">
              <label for="">Address Prefix</label>
              <input
                type="text"
                class="form-control"
                v-model="domainAddress.address_prefix"
                placeholder="info"
              />
            </div>
            <div class="col-auto mt-auto">
              <p class="mb-0 fw-bold mb-2">@{{ domain.domain }}</p>
            </div>
            <div class="col-md-4 mt-auto py-3 py-md-0">
              <label for="">Mail From Name</label>
              <input
                type="text"
                v-model="domainAddress.mail_from_name"
                class="form-control"
                placeholder="Example"
              />
            </div>
            <div class="col-auto ms-auto mt-2 align-self-end">
              <button class="btn btn-primary">
                <i class="far fa-plus me-2"></i>Add Address
              </button>
            </div>
          </div>
        </form>

        <!--  -->
        <h5 class="fw-bold text-primary mb-3">Senders</h5>
        <div class="row">
          <div class="col">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="a in addresses"
                :key="'add-' + a.id"
              >
                {{ a.full_address }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="addresses.length > 0">
      <form @submit.prevent="sendTestEmail">
        <h4 class="text-dark fw-bold">Test Email</h4>
        <p class="text-dark mb-4">Your domain has...</p>

        <div class="row mb-4">
          <div class="col-12 col-md-4">
            <label for="" class="fw-bold">From address</label>
            <select class="form-control" v-model="email.from">
              <option value="">Select from address</option>
              <option v-for="a in addresses" :key="a.id" :value="a.id">
                {{ a.full_address }}
              </option>
            </select>
          </div>

          <div class="col-12 col-md-4 py-3 py-md-0">
            <label for="" class="fw-bold">Email address</label>
            <input
              type="email"
              class="form-control"
              v-model="email.email"
              placeholder="jane@doe.com"
              required
            />
          </div>

          <div class="col-12 col-md-4">
            <label for="" class="fw-bold">Test email subject</label>
            <input
              type="text"
              class="form-control"
              v-model="email.subject"
              placeholder="Subject"
              required
            />
          </div>
        </div>
        <div class="row mb-3">
          <label for="" class="fw-bold mb-1"> Test Email Message </label>
          <div>
            <Wysiwyg
              v-model="email.body"
              :disable-full-screen="true"
              required
            />
          </div>
        </div>

        <div class="row">
          <div class="col-auto">
            <button
              class="btn btn-primary"
              :disabled="
                !email.email &&
                !email.from &&
                !email.subject &&
                email.body.length > 2
              "
              @click="sendTestEmail"
            >
              <i class="far fa-envelope me-2"></i>Send email
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Wysiwyg from "@/views/components/input/Wysiwyg";
import "vue-tiptap-wysiwyg/dist/vue-tiptap-wysiwyg.css";

export default {
  props: {
    domain: {
      type: Object,
    },
  },
  data() {
    return {
      addresses: [],
      domainAddress: {
        address_prefix: "",
        mail_from_name: "",
        authorised_domain_id: this.$route.params.id,
      },
      email: {
        subject: "",
        body: "",
        email: "",
        from: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    async fetchAddresses() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/domains/" +
          this.$route.params.id +
          "/addresses"
      );

      this.addresses = data;
    },
    async addDomainAddress() {
      const { data } = await this.$axios.post(
        process.env.VUE_APP_API_URL + "/authorised-domain-addresses",
        this.domainAddress
      );

      this.domainAddress = {
        address_prefix: "",
        mail_from_name: "",
        authorised_domain_id: this.$route.params.id,
      };
      this.fetchAddresses();
      this.$EventBus.$emit("alert", { message: data.message });
    },
    sendTestEmail() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/domains/" +
            this.$route.params.id +
            "/test-send",
          this.email
        )
        .then(({ data }) => {
          this.email = {
            subject: "",
            body: "",
            email: "",
            from: "",
          };
          this.$EventBus.$emit("alert", { message: data.message });
        });
    },
  },
  mounted() {
    this.fetchAddresses();
  },
  components: {
    Wysiwyg,
  },
};
</script>

<style>
</style>
