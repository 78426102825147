<template>
  <div>
    <div class="alert alert-dark mb-5" role="alert">
      Now that we have verified ownership of the domain we need to add the
      following records to the domain DNS Zone.
    </div>

    <div>
      <h5 class="fw-bolder text-primary mb-0">Records to be updated</h5>

      <div class="list-group list-group-flush">
        <div
          class="my-3 py-1 px-3 bg-light"
          v-for="(value, key) in domainCopy.sendgrid_dns"
          :key="key"
        >
          <div class="row">
            <div class="d-flex bg-white my-0 my-sm-0 py-2 px-3">
              <p class="fw-bold col-2 col-sm-1 mb-0 align-self-center">Type</p>
              <p
                class="
                  col-8 col-sm-10
                  mb-0
                  px-2
                  align-self-center
                  cursor-pointer
                "
                v-tooltip="'Click to copy'"
                v-clipboard:copy="value.type"
              >
                <small>
                  {{ value.type }}
                </small>
              </p>
              <div class="col-2 col-sm-1 d-flex justify-content-center mb-0">
                <i
                  class="
                    btn btn-light
                    align-self-center
                    fad
                    fa-copy
                    cursor-pointer
                  "
                  v-tooltip="'Click to copy'"
                  v-clipboard:copy="value.type"
                />
              </div>
            </div>
            <div class="d-flex bg-white my-1 my-sm-1 py-2 px-3">
              <p class="fw-bold col-2 col-sm-1 mb-0 align-self-center">Host</p>
              <p
                class="
                  col-8 col-sm-10
                  mb-0
                  px-2
                  align-self-center
                  cursor-pointer
                "
                v-tooltip="'Click to copy'"
                v-clipboard:copy="value.host"
              >
                <small>
                  {{ value.host }}
                </small>
              </p>
              <div class="col-2 col-sm-1 d-flex justify-content-center mb-0">
                <i
                  class="
                    btn btn-light
                    align-self-center
                    fad
                    fa-copy
                    cursor-pointerv
                  "
                  v-tooltip="'Click to copy'"
                  v-clipboard:copy="value.host"
                />
              </div>
            </div>
            <div class="d-flex bg-white my-0 py-2 px-3">
              <p class="fw-bold col-2 col-sm-1 mb-0 align-self-center">Data</p>
              <p
                class="
                  col-8 col-sm-10
                  mb-0
                  px-2
                  align-self-center
                  cursor-pointer
                "
                v-tooltip="'Click to copy'"
                v-clipboard:copy="value.data"
              >
                <small>
                  {{ value.data }}
                </small>
              </p>
              <div class="col-2 col-sm-1 d-flex justify-content-center mb-0">
                <i
                  class="
                    btn btn-light
                    align-self-center
                    fad
                    fa-copy
                    cursor-pointer
                  "
                  v-tooltip="'Click to copy'"
                  v-clipboard:copy="value.data"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-auto">
        <button class="btn btn-primary" @click="verifySendgrid">
          Verify DNS
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domain: {
      type: Object,
    },
  },
  data() {
    return {
      domainCopy: this.domain,
    };
  },
  methods: {
    async verifySendgrid() {
      const { data } = await this.$axios.post(
        process.env.VUE_APP_API_URL +
          "/domains/" +
          this.$route.params.id +
          "/verify-sendgrid"
      );
      if (data.verified) {
        this.domainCopy = data.domain;
        this.$EventBus.$emit("alert", { message: data.message });
        this.$emit("updated", data.domain);
      } else {
        this.$EventBus.$emit("alert", {
          message: "DNS record not found, please check back periodically",
        });
        this.$router.push("/domains");
      }
    },
  },
};
</script>

<style>
</style>