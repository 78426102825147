<template>
  <div>
    <vue-tiptap-wysiwyg
        v-model="content"
        :class="classList"
        :input-type="inputType"
        :keyboard-submit="keyboardSubmit"
        @keyboardSubmit="$emit('keyboardSubmit')"
        required>
      <template v-slot:imageupload="scope">
        <AddImageModal
            :input-type="inputType"
            @inserted="scope.insert"
            @closed="scope.cancel" />
      </template>
    </vue-tiptap-wysiwyg>
  </div>
</template>

<script>
import VueTiptapWysiwyg from "vue-tiptap-wysiwyg";
import AddImageModal from "./wysiwg-partials/AddImageModal";
export default {
  props: ["value", "classList", "inputType", "keyboardSubmit"],
  data() {
    return {
      content: this.value
    }
  },
  watch: {
    content() {
      this.$emit("input", this.content)
    }
  },
  components: {
    AddImageModal,
    VueTiptapWysiwyg
  }
};
</script>

<style>
</style>
