<template>
  <div class="container px-0 px-sm-2">
    <div class="card border-0 shadow-sm" v-if="domain">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col my-auto">
            <span
              v-if="domain.verified"
              class="badge rounded-pill btn-success px-2 pt-1"
            >
              Verified
            </span>
            <span v-else class="badge rounded-pill btn-dark px-2 pt-1">
              Not verified
            </span>
            <h2 class="text-primary text-uppercase fw-bolder mt-1 mb-0">
              {{ domain.domain }}
            </h2>
          </div>
          <div
            class="
              col-auto
              ms-auto
              d-flex
              flex-column
              justify-content-end
              align-content-end
            "
          >
            <router-link to="/domains/" class="btn btn-light text-dark">
              <i class="far fa-arrow-left me-1 d-none d-md-inline-block" />Back
            </router-link>
          </div>
        </div>
        <!-- Add crank DNS record -->
        <step-1
          :domain="domain"
          v-if="domain.status === 'pending'"
          @updated="updated"
        ></step-1>

        <!-- Crank record added - Add Sendgrid DNS -->
        <step-2
          :domain="domain"
          v-if="domain.status === 'swandoola_verified'"
          @updated="updated"
        ></step-2>

        <!-- Verify Sendgrid DNS added & create subuser account -->
        <step-3
          :domain="domain"
          v-if="domain.status === 'sendgrid_verified'"
          @updated="updated"
        ></step-3>

        <!-- Add Senders + Send Test Email -->
        <step-4 :domain="domain" v-if="domain.status === 'verified'"></step-4>

        <!--  -->

        <div class="pt-4">
          <div>
            <button class="btn btn-outline-dark btn-sm" @click="removeDomain">
              <i class="far fa-trash me-2"></i>Remove domain
            </button>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Step1 from "./view-partials/Step1";
import Step2 from "./view-partials/Step2";
import Step3 from "./view-partials/Step3";
import Step4 from "./view-partials/Step4";

export default {
  data() {
    return {
      domain: null,
    };
  },
  methods: {
    updated(domain) {
      this.domain = domain;
    },
    removeDomain() {
      if (confirm("Are you sure you wish to delete this domain?")) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/domains/" + this.$route.params.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            const vm = this;
            setTimeout(function () {
              vm.$router.push("/domains");
            }, 500);
          });
      }
    },
    fetchDomain() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/domains/" + this.$route.params.id)
        .then(({ data }) => {
          this.domain = data;
        });
    },
  },
  created() {
    this.fetchDomain();
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
};
</script>

<style>
</style>
