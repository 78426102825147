<template>
  <div>
    <div>
      <div class="alert alert-dark mb-2 mb-sm-3" role="alert">
        To connect your domain with Swandoola you must add the following
        verification DNS record to your domain
        <span class="fw-bold">{{ domain.domain }}</span
        >. We are verifying your ownership of the domain using this method.
      </div>

      <div class="card border-0 shadow-none mb-3">
        <div class="card-body pb-0 pt-2 px-0">
          <div class="d-flex bg-light my-2 my-sm-1 py-2 px-3">
            <p class="col-5 col-sm-3 mb-0">Record type</p>
            <p class="fw-bold col-7 col-sm-9 mb-0">Text</p>
          </div>
          <div class="d-flex bg-light my-2 my-sm-1 py-2 px-3">
            <p class="col-5 col-sm-3 mb-0">Host</p>
            <p class="fw-bold col-7 col-sm-9 mb-0">@</p>
          </div>
          <div class="d-flex bg-light my-2 my-sm-1 py-2 px-3">
            <p class="col-5 col-sm-3 mb-0">Value</p>
            <div
              class="col-7 col-sm-9 cursor-pointer"
              v-tooltip="'Click to copy'"
              v-clipboard:copy="domainCopy.dns_txt_record"
            >
              <p class="fw-bold d-inline mb-0">
                {{ domainCopy.dns_txt_record }}
              </p>
              <i class="px-sm-3 px-2 far fa-copy"></i>
            </div>
          </div>
          <div class="d-flex bg-light my-2 my-sm-1 py-2 px-3">
            <p class="col-5 col-sm-3 mb-0">TTL</p>
            <p class="fw-bold col-7 col-sm-9 mb-0">Hour</p>
          </div>
          <div class="d-flex py-2 mt-4">
            <div class="col-auto px-0">
              <button class="btn btn-primary" @click="connectDomain">
                Connect domain
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domain: {
      type: Object,
    },
  },
  data() {
    return {
      domainCopy: this.domain,
    };
  },
  methods: {
    async connectDomain() {
      const { data } = await this.$axios.post(
        process.env.VUE_APP_API_URL +
          "/domains/" +
          this.$route.params.id +
          "/verify-swandoola"
      );
      if (data.verified) {
        this.domainCopy = data.domain;
        this.$EventBus.$emit("alert", { message: data.message });
        this.$emit("updated", data.domain);
      } else {
        this.$EventBus.$emit("alert", {
          message: "DNS record not found, please check back periodically",
        });
        this.$router.push("/domains");
      }
    },
  },
};
</script>

<style>
</style>